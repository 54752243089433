import { computed } from 'vue';

export default function useSharedButtonStyles(hideTextOnMobile, icon) {
  function calculateButtonTextCss(extraCss) {
    return computed(() => {
      let css = 'tw-text-base tw-font-medium tw-normal-case tw-tracking-normal tw-mx-1';

      if (hideTextOnMobile && icon) {
        css += ' tw-hidden lg:tw-block';
      }

      if (extraCss) {
        css += ` ${extraCss}`;
      }

      return css;
    });
  }

  return {
    calculateButtonTextCss,
  };
}
