import useSharedButtonStyles from '@/src/common/composables/buttons/useSharedButtonStyles.js';

export default function useDefaultStyles(props) {
  const { calculateButtonTextCss } = useSharedButtonStyles(props.hideTextOnMobile, props.icon);

  const buttonColor = '';
  const buttonVariant = 'outlined';
  const buttonCss =
    'tw-border-earl-300 tw-bg-white hover:tw-bg-white hover:tw-shadow-btn-hover dark:tw-border-earl-600 dark:tw-bg-earl-900 dark:hover:tw-bg-earl-600';
  const iconCss = 'tw-text-black dark:tw-text-white';
  const buttonTextCss = calculateButtonTextCss('tw-text-black dark:tw-text-white');

  return {
    buttonColor,
    buttonVariant,
    buttonCss,
    iconCss,
    buttonTextCss,
  };
}
