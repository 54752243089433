import useSharedButtonStyles from '@/src/common/composables/buttons/useSharedButtonStyles.js';

export default function useBasicStyles(props) {
  const { calculateButtonTextCss } = useSharedButtonStyles(props.hideTextOnMobile, props.icon);

  const buttonColor = '';
  const buttonVariant = 'flat';
  const buttonCss = 'tw-bg-transparent hover:tw-underline';
  const iconCss = 'tw-text-black dark:tw-text-white';
  const buttonTextCss = calculateButtonTextCss('tw-text-black dark:tw-text-white');

  return {
    buttonColor,
    buttonVariant,
    buttonCss,
    iconCss,
    buttonTextCss,
  };
}
