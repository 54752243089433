import useSharedButtonStyles from '@/src/common/composables/buttons/useSharedButtonStyles.js';

export default function useBrandStyles(props) {
  const { calculateButtonTextCss } = useSharedButtonStyles(props.hideTextOnMobile, props.icon);

  const buttonColor = 'brand';
  const buttonVariant = 'flat';
  const buttonCss = 'hover:tw-bg-trappist1-600 dark:hover:tw-bg-trappist1-300';
  const iconCss = 'tw-text-white dark:tw-text-black';
  const buttonTextCss = calculateButtonTextCss('tw-text-white dark:tw-text-black');

  return {
    buttonColor,
    buttonVariant,
    buttonCss,
    iconCss,
    buttonTextCss,
  };
}
