<template>
  <v-btn
    :color="buttonColor"
    density="default"
    :variant="buttonVariant"
    :class="[buttonCss, { 'tw-justify-center': !text }]"
    :aria-label="ariaLabel || text || icon"
    slim
    min-width="0.65rem"
  >
    <v-icon
      v-if="icon"
      :size="iconSize"
      :class="[iconCss, { 'tw-mr-0': !text }]"
    >
      {{ icon }}
    </v-icon>
    <span
      v-if="text"
      :class="buttonTextCss"
    >
      {{ text }}
    </span>
  </v-btn>
</template>

<script setup>
import useSpiceworksButtonStyles from '@/src/common/composables/useSpiceworksButtonStyles.js';

const props = defineProps({
  buttonType: {
    type: String,
    default: 'default',
    validator: function (value) {
      const allowedValues = [
        'default',
        'basic',
        'primary',
        'primary-ghost',
        'inverted-ghost',
        'brand',
      ];
      if (!allowedValues.includes(value)) {
        console.error(
          `SpiceworksButton invalid prop: Expected 'buttonType' to be one of ${allowedValues.join(
            ', '
          )}, but got '${value}'`
        );
      }
      // we aren't trying to throw a Vue validation error here. We only want to
      // log an error message to the console. Thus we always return true.
      return true;
    },
  },
  icon: { type: String, default: null },
  iconSize: { type: String, default: 'large' },
  text: { type: String, default: '' },
  hideTextOnMobile: { type: Boolean, default: false },
  ariaLabel: { type: String, default: null },
});

const { buttonColor, buttonVariant, buttonCss, iconCss, buttonTextCss } =
  useSpiceworksButtonStyles(props);
</script>

<style lang="scss">
.v-btn__prepend {
  @apply tw-m-0;
}
</style>
