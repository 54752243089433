import useSharedButtonStyles from '@/src/common/composables/buttons/useSharedButtonStyles.js';

export default function useInvertedGhostStyles(props) {
  const { calculateButtonTextCss } = useSharedButtonStyles(props.hideTextOnMobile, props.icon);

  const buttonColor = '';
  const buttonVariant = 'outlined';
  const buttonCss =
    'tw-border-white tw-bg-transparent hover:tw-bg-transparent hover:tw-shadow-btn-hover';
  const iconCss = 'tw-text-white';
  const buttonTextCss = calculateButtonTextCss('tw-text-white');

  return {
    buttonColor,
    buttonVariant,
    buttonCss,
    iconCss,
    buttonTextCss,
  };
}
