import useSharedButtonStyles from '@/src/common/composables/buttons/useSharedButtonStyles.js';

export default function usePrimaryGhostStyles(props) {
  const { calculateButtonTextCss } = useSharedButtonStyles(props.hideTextOnMobile, props.icon);

  const buttonColor = '';
  const buttonVariant = 'outlined';
  const buttonCss =
    'tw-border-dusk-500 tw-bg-white hover:tw-bg-white hover:tw-shadow-btn-hover dark:tw-border-dusk-200 dark:tw-bg-earl-900 dark:hover:tw-bg-dusk-600';
  const iconCss = 'tw-text-dusk-500 dark:tw-text-dusk-200';
  const buttonTextCss = calculateButtonTextCss('tw-text-dusk-500 dark:tw-text-dusk-200');

  return {
    buttonColor,
    buttonVariant,
    buttonCss,
    iconCss,
    buttonTextCss,
  };
}
