import useDefaultStyles from '@/src/common/composables/buttons/useDefaultStyles.js';
import useBasicStyles from '@/src/common/composables/buttons/useBasicStyles.js';
import usePrimaryStyles from '@/src/common/composables/buttons/usePrimaryStyles.js';
import usePrimaryGhostStyles from '@/src/common/composables/buttons/usePrimaryGhostStyles.js';
import useInvertedGhostStyles from '@/src/common/composables/buttons/useInvertedGhostStyles.js';
import useBrandStyles from '@/src/common/composables/buttons/useBrandStyles.js';

export default function useSpiceworksButtonStyles(props) {
  const composables = {
    default: useDefaultStyles,
    basic: useBasicStyles,
    primary: usePrimaryStyles,
    'primary-ghost': usePrimaryGhostStyles,
    'inverted-ghost': useInvertedGhostStyles,
    brand: useBrandStyles,
  };

  const composable = composables[props.buttonType] || useDefaultStyles;
  const { buttonColor, buttonVariant, buttonCss, iconCss, buttonTextCss } = composable(props);

  return {
    buttonColor,
    buttonVariant,
    buttonCss,
    iconCss,
    buttonTextCss,
  };
}
